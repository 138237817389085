import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Divider from '../components/Divider'
import FadeIn from '../components/FadeIn'

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <FadeIn duration={0.5} id="intro" elementType="section">
        <div className="lede">
          <h1 style={{ marginTop: 0 }}>
            Ready to book your next appointment or free consultation?
          </h1>
        </div>
      </FadeIn>
      <FadeIn elementType="section">
        <div className="lede">
          <div className="contact-info">
            <p className="contact-text">
              Give us a call or fill out the form below and we will get back to
              you as soon as possible.
            </p>
            <div className="contact-details">
              <span>
                <a
                  href="https://g.page/denovo-permanent-cosmetics"
                  className="unstyled"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  7703 NE 72nd Ave, Vancouver, WA <br />
                  Located inside Club Green Meadows
                </a>
              </span>
              <span>
                <i>email:</i>{' '}
                <a href="mailto: laurinda@denovobeauty.com" className="unstyled">
                  laurinda@denovobeauty.com
                </a>
              </span>
              <span>
                <i>phone:</i>{' '}
                <a href="tel: 3608969538" className="unstyled">
                  360.896.9538
                </a>
              </span>
              <span>
                <i>fax:</i> 866.631.7174
              </span>
              <span>
                <i>hours:</i> Tues–Fri, 10am–6pm
              </span>
            </div>
          </div>
        </div>
      </FadeIn>
      <FadeIn duration={0.75}>
        <form
          id="contact"
          name="contact"
          data-netlify="true"
          method="post"
          netlify-honeypot="bot-field"
          action="/success"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <div>
            <div className="half">
              <label htmlFor="name">Your name</label>
              <input name="name" id="name" type="text" required />
            </div>
            <div className="half">
              <label htmlFor="email">Email address</label>
              <input name="email" id="email" type="email" required />
            </div>
          </div>
          <div>
            <label htmlFor="subject">Subject</label>
            <input
              name="subject"
              id="subject"
              type="text"
              required
              autoComplete="off"
            />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" required />
          </div>
          <input
            name="submit"
            type="submit"
            value="Send Message"
            className="button"
          />
        </form>
      </FadeIn>
      <Divider />
    </Layout>
  )
}
export default ContactPage
